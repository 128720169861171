.catContainer{
    margin-top: 2rem;
    a{
        text-decoration: none;
    }
    .catBox{
        border: 1px solid gainsboro;
        margin: 1rem;
        padding: 0.5rem;
        background-color: #fff;
        .catImg{
            height: 10rem;
            width: 100%;
            object-fit: cover;
            margin: auto;
            display: block;
        }
        .headText{
            font-size: 1rem;
            text-align: center;
            text-transform: capitalize;
        }
    }
    
}
