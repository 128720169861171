@import '../../../styles/base.scss';

.cartItemBox{
    margin-bottom: 0.5rem;
    // box-shadow: 0 2px 18px 0 rgba(182, 178, 178, 50%);
    padding: 0.5rem;
    border-bottom: 1px solid gainsboro;
    img{
        height: 7rem;
        margin: auto;
        display: block;
        @include mobile(){
            width: 100%;
        }
    }
    p{
        font-size: 0.9rem;
        color: #666;
        margin-bottom: 0.4rem;
    }
    span{
        color: #666;
        font-weight: 500;
        font-size: 0.9rem;
    }
    .inputQty{
        width: 4rem;
        text-align: center;
    }
    .deleteBox{
        background-color: #E5432D;
        text-align: center;
        font-size: 0.9rem;
        color: #fff;
        padding: 0.2rem;
        width: 4rem;
        float: right;
        cursor: pointer;
    }
}