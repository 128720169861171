.orderBox{
    .headingText{
        font-weight: 700;
        font-size: 1.2rem;
        padding: 1rem;
    }
    .orderItem{
        border: 1px solid gainsboro;
        // box-shadow: 0 2px 18px 0 rgba(182, 178, 178, 50%);
        background-color: #fff;
        margin-top: 0.5rem;
    }
    p{
        padding-top: 0.5rem;
        font-weight: 500;
        margin-bottom: 0.5rem;
    }
}