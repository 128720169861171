.header{
    background-color: #ffc107;
    color: #333;
    .btn-close{
        background-color: gold!important;
    }
}

.drawarBox{
    .navLink{
        border-bottom: 1px solid gainsboro;
        padding: 0.8rem;
        a{
            text-decoration: none;
            color: #111;
            font-weight: 600;
            text-transform: capitalize;
        }
    }
    .seprater{
        border-top: 2px solid #F9C132;
    }
}
