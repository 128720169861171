// for media screen
$phone: 260px;
$tablet: 768px;
$desktop: 1024px;

// color variable
$label: #333;
$input: #f7f5f3;
$primaryHead: #333;

$menuColor: #000;
$linkColor: #000;
$activeLinkColor: #ffc107;
$titleColor: #000;
$textColor: #000;
$headerBgColor: #fff;
$borderColor: #dcdcdc;
$cardListBgColor: #f6f9fb;
$borderBtmColor: rgb(220, 220, 220);
$menuHoverBg: #e2e4e6;
$error: #ed462f;
$primaryBorder: #dcdcdc;
$usernameColor: #333;
$arrowIconColor: #666;
$radius: 0.2rem;

$boxShadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
  0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);

// color variable which will be delete soon
$black: #000;
$white: #fff;
$yellow: #fcd835;
$gray: #eef0f1;
$red: #ed462f;
$blue: #497ec5;
$green: #52b695;
$menu: #333;
$hyperLink: #333;
$tags: #0d0c22;

// media query mixin
@mixin mobile {
  @media (min-width: #{$phone}) and (max-width: #{$tablet - 1px}) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: #{$tablet}) and (max-width: #{$desktop - 1px}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$desktop}) {
    @content;
  }
}

@mixin error($size: 0.8rem, $weight: 600) {
  font-size: $size;
  font-weight: $weight;
  color: $error;
  text-align: right;
  padding: 0.2rem;
}

.errorMsg {
  @include error();
}

.offcanvas-start{
@include mobile(){
  width: 75%!important;
}
}
.offcanvas{
  z-index: 99999999999999!important;
}

.noOutline{
  &:focus{
    box-shadow: none!important;
  }
}