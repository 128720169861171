@import '../../../styles/base.scss';

.sliderImg{
    height: 30rem;
    width: 100%;
    display: block;
    object-fit: cover;
    @include mobile(){
        height: 12rem;
    }
}