@import '../../styles/base.scss';

.prodDetailsPage{
    margin-top: 1rem;
    padding: 0.5rem;
    @include mobile(){
        margin-top: 0.2rem;
        // padding: 0;
    }
    .prodImg{
        height: 15rem;
        object-fit: fill;
        margin: auto;
        display: block;
        border: 1px solid gainsboro;
        @include mobile(){
            width: 100%;
        }
    }
    .headText{
        font-size: 1.5rem;
        color: $primaryHead;
        font-weight: 600;
        padding-top: 0.5rem;
    }
    .context{
        font-size: 0.9rem;
        text-align: justify;
        margin-bottom: 0.5rem;
        color: #444;
    }
    .spanText{
        margin-right: 0.5rem;
        padding: 0.2rem;
        margin-bottom: 0.2rem;
        font-size: 0.9rem;
        @include mobile(){
            display: inline-block;
        }
    }
    .stockStatus{
        color: #444;
        text-transform: capitalize;
        font-size: 0.9rem;
        border-radius: 0.2rem;
        background-color:aquamarine;
        width: 5rem;
        text-align: center;
    }
    .titleText{
        font-size: 1.2rem;
        margin-top: 1rem;
    }
}
