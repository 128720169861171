@import '../../../styles/base.scss';

.cetegoryBox{
    padding: 0.4rem;
    border-radius: 0.2rem;
    margin-bottom: 1rem;
    box-shadow: 0 1px 2px 0 rgba(182, 178, 178, 50%);
    background-color: #fff;
    height: 15rem;
    @include mobile(){
        padding: 0.2rem;
        margin-bottom: 0.2rem;
        height: 10rem;
    }
    &:hover{
        transform: scale(1.05);
        transition: ease-in-out;
    }
    img{
        height: 12rem;
        width: 100%;
        @include mobile(){
            height: 7rem;
        }
    }
    h2{
        text-align: center;
        color: $primaryHead;
        text-transform: capitalize;
        font-size: 0.9rem;
        padding: 0.4rem;
        font-weight: 500;
        text-decoration: none;
        @include mobile(){
            font-size: 0.72rem;
            padding: 0.1rem;
        }
    }
}