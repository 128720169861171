@import '../../../styles/base.scss';

.navigationBox{
    box-shadow: 0 2px 18px 0 rgba(182, 178, 178, 50%);
    background-color: #fff;
    @include mobile(){
        background-color: #fff;
    }
}
.topHeader{
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    // z-index: 9999999;
    .brandBox{
        // @include mobile(){
         text-align: center;
        // }
        .brandName{
            color: #444;
            font-weight: 700;
            font-size: 1.5rem;
            text-decoration: none;
        }
        .brandLogo{
            height: 3rem;
            @include mobile(){
                height: 2rem;
                float: right;
            }
        }
        .crossImg{
            height: 2rem;
            cursor: pointer;
            padding-left: 1rem;
        }
    }
    .mobileMenu{
        // float: left;
        .menuIcon{
            height: 2rem;
            cursor: pointer;
        }
        .searchImg{
            height: 2rem;
            cursor: pointer;
            padding-left: 0.5rem;
        }
    }
    .searchContainer{
        position: absolute;
        top: 8px;
        z-index: 999;
        background-color: #fff;
        width: 100%;
    }
    .cartBox{
        float: right;
        .cartImg{
            height: 2rem;
            cursor: pointer;
        }
        .searchImg{
            height: 2rem;
            cursor: pointer;
            padding-right: 0.5rem;
        }
        a{
            color: #2874F0;
            font-weight: 500;
            text-decoration: none;
        }
    }
    
}
.headerBox{
    background-color: #444;
    border-radius: 0;
    .navBar{
        padding: 0;
        .navLink{
            a, div{
                color: #fcfcfc;
                font-weight: 500;
                font-size: 0.9rem;
                padding-right: 1.2rem;
                padding-left: 1.2rem;
                padding-top: 0.5rem;
                padding-bottom: 0.5rem;
                text-decoration: none;
                text-transform: capitalize;
                cursor: pointer;
                &:hover{
                    background-color: #2874F0;
                }
            }
        }
    }
    
}