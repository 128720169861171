@import '../../../styles/base.scss';

.productCard{
    margin-top: 1rem;
    // border: 1px solid gainsboro;
    padding: 0.5rem;
    text-decoration: none;
    .productImg{
        height: 20rem;
        width: 100%;
        object-fit: cover;
        @include mobile(){
            height: 10rem;
        }
    }
    .headingText{
        font-size: 0.9rem;
        font-weight: 600;
        padding: 0.2rem;
        color: $primaryHead;
        margin-top: 0.5rem;
    }
    .infoBox{
        display: inline-flex;
        width: 100%;
        padding-bottom: 0.5rem;
        .conText{
            flex: 1 1;
            justify-content: center;
            color: $label;
            font-weight: 700;
            font-size: 0.8rem;
            &:last-child{
                text-align: right;
                font-size: 0.8rem;
                font-weight: 700;
                color: #117a8b;
            }
        }
    }

    &:hover{
        box-shadow: 0 2px 18px 0 rgba(182, 178, 178, 50%);
        border-radius: 0.2rem;
        transition: ease-in-out;
        @include mobile(){
            box-shadow: none;
        }
    }
    a{
        text-decoration: none;
    }
    a:hover{
        text-decoration: none;
    }
    .whatsappImg{
        height: 2rem;
        float: right;
        box-shadow: 0 2px 18px 0 rgba(182, 178, 178, 50%);
        border-radius: 50%;
    }
    .editBtn{
        border-radius: 50%;
        background-color: #0dcaf0;
        color: #fff;
        font-size: 0.8rem;
        position: absolute;
        padding: 0.5rem;
        box-shadow: 0 2px 18px 0 rgba(182, 178, 178, 50%);
        border: 0;
    }
    
}