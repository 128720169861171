@import '../../styles/base.scss';

.productsBox{
    a{
        text-decoration: none;
    }
    @include mobile(){
        padding-left: 5px;
        padding-right: 5px;
        &:last-child{
            margin-bottom: 1.5rem;
        }
    }
}
.loadMoreBtn{
    margin-top: 2rem;
}