.orderFormBox{
    z-index: 999999999999999999;
    .errMsg{
        font-size: 0.85rem;
        color: red;
    }
    label{
        font-size: 0.85rem;
        margin-top: 0.5rem;
    }
    input{
        border-radius: 0;
        &:focus{
            box-shadow: none;
        }
    }
}