@import '../../styles/base.scss';

.loginPage{
    .loginBox{
        box-shadow: 0 2px 18px 0 rgba(182, 178, 178, 50%);
        padding: 2rem;
        margin-top: 5rem;
        border-radius: 0.2rem;
        @include mobile(){
            box-shadow: none;
        }
        .headText{
            text-align: center;
            font-weight: 700;
            font-size: 1.8rem;
            color: $primaryHead;
            padding: 0.5rem;
        }
        label{
            font-size: 0.9rem;
            color: $label;
        }
        input, textarea, select{
            background-color: $input;
            font-size: 0.95rem;
            border-radius: 0;
            &:focus{
                outline: 1px solid $input;
                border-color: $input;
                box-shadow: none;
            }
        }
        button{
            font-weight: 500;
            margin-top: 1rem;
        }
    }
}