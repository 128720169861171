.homePage{
    // padding-top: 1rem;
    padding-bottom: 5rem;
    a{
        text-decoration: none!important;
    }
    .headText{
        font-size: 0.8rem;
        font-weight: 600;
        margin-top: 0.5rem;
    }
    .catBtn{
        text-align: right;
        text-decoration: none;
        font-size: 0.8rem;
        color: #3FAEEF;
        margin-top: 0.5rem;
    }
    .categorySection{
        background-color: #F5F5F5;
        padding-top: 1rem;
        .categoryBox{
            padding: 0.5rem;
            width: 100%;
            a{
                text-decoration: none;
            }
        }
    }
    
    .loadMoreBtn{
        margin-top: 2rem;
    }
    
}