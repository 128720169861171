@import '../../styles/base.scss';

.addProductPage{
    box-shadow: 0 2px 18px 0 rgba(182, 178, 178, 50%);
    padding: 1rem;
    margin-top: 4rem;
    margin-bottom: 4rem;
    border-radius: 0.2rem;
    @include mobile(){
        margin-top: 1rem;
        margin-bottom: 1rem;
        // box-shadow: none;
    }
    .imgUploadBox{
        margin-bottom: 1rem;
        .imgUpload{
            height: 5rem;
            margin: auto;
            display: block;
        }
    }
    .preViewImg{
        height: 8rem;
        margin: auto;
        display: block;
        object-fit: fill;
    }
    label{
        font-size: 0.9rem;
        color: $label;
    }
    input, textarea, select{
        background-color: $input;
        font-size: 0.95rem;
        border-radius: 0;
        &:focus{
            outline: 1px solid $input;
            border-color: $input;
            box-shadow: none;
        }
    }
    button{
        font-weight: 500;
    }
    .submitBtn{
        margin-top: 1rem;
    }
}
